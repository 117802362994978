import path from 'path';
import { graphql } from 'gatsby';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { form as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    Link,
    PersonalLoanForm,
    ScrollMarker,
    SEO,
    TrustIcons,
    Variants,
} from '@brandedholdings/react-components';
import SectionHero from '../components/landing-pages/sections/Hero';
import LogoCnn from '../images/logos/logo-cnn.svg';
import LogoFoxNews from '../images/logos/logo-fox-news.svg';
import LogoMsnbc from '../images/logos/logo-msnbc.svg';
import LogoVh1 from '../images/logos/logo-vh1.svg';
import LogoHallmarkChannel from '../images/logos/logo-hallmark-channel.svg';

import LogoAsSeenOnTV from '../images/logos/tv.svg';
import LogoAsHeardOnRadio from '../images/logos/as-heard-on-radio.svg';

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variant: 'default',
            showRLA: true,
            finalLoanAmounts: {
                min: 0,
                max: 0
            },
        };
        this.setRLA = this.setRLA.bind(this);
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    setRLA(e,obj) {
        this.setState({
            finalLoanAmounts: {
                ...obj
            }
        }, () => {
            this.setupApplicationFocus(e);
        });
    }

    setupApplicationFocus(e) {
        if (this._personalLoanForm !== null) {
            e.preventDefault();
            ScrollMarker.scrollToMarker('heroStart');
            this._personalLoanForm.getWrappedInstance().focusApplication();
        }
    }

    componentDidMount() {
        const match = document.location.href.toLowerCase().match(/(([?&#])startnow=true|([?&#])promoid|([?&#])promo_id|([?&#])utm_source=revpie)/i);

        if (match instanceof Array) {
            this.setState({ variant: 'x' });
        }
    }
    render() {
        const page = this.props;
        this._personalLoanForm = null;
        const {
            finalLoanAmounts,
            showRLA,
        } = this.state;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const variants = new Variants()
        const enableDebtQuestions = variants.active.debt_questions && (variants.active.debt_questions === 'test');
        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);

        const maxLoanValue = 5000;
        const terms = "/terms.html";
        const privacy = "/privacy.html";
        const electronicDisclosure = "/terms.html#electronic-disclosure";
        const creditDisclosure = "/terms.html#credit-disclosure";
        const partners = "/partners.html";
        // const callCenterPhoneNumber = siteMeta.callCenter.phoneNumber || '';
        // const callCenterMessage = siteMeta.callCenter.message || '';
        // const clickToCallCenter = "tel:1" + siteMeta.callCenter.phoneNumber.replace(/-| |[()]/g, '');
        return (
            <PageWrapper landingPage={"true"}>
                <SEO post={post} />
                <div className="hero__funnel">
                    <BackgroundImage
                        className="background__gatsby hero hero__alternative background__graphic"
                        fluid={pageImages.hero.image.fluid}
                    >
                    </BackgroundImage>
                    <BackgroundImage
                        className="hero background background__spokesperson text-shadow__blue headlines__bold hide-on-init"
                        fluid={pageImages.spokesperson.image.fluid}
                        style={{ backgroundColor: 'transparent' }}
                        durationFadeIn={0}
                        loading="eager"
                    >
                        <div className="layout">
                            <div className="hero--message">
                                <SectionHero
                                    setRLA={this.setRLA}
                                />
                                <div className="form__footer">
                                    {/* {callCenterPhoneNumber && (
                                        <a 
                                            href={clickToCallCenter}
                                            className="call-center"
                                        >
                                            <span className="call-center--message">{callCenterMessage + ' '}</span>
                                            <span className="call-center--phone">{callCenterPhoneNumber}</span>
                                        </a>
                                    )} */}
                                    <TrustIcons icons={['mcafee', 'secured']} siteMeta={siteMeta} height={26} />
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                    <div className="background form--hidden">
                        <div className="layout">
                            <div className="form split-form">
                                <PersonalLoanForm
                                    minLoanValue={1000}
                                    maxLoanValue={maxLoanValue}
                                    modalDisclaimerText={siteMeta.modalDisclaimer}
                                    enableOverlay={true}
                                    ref={(personalLoanForm) => { this._personalLoanForm = personalLoanForm; }}
                                    trustIcons={['mcafee', 'secured']}
                                    urlTerms={terms}
                                    urlPrivacy={privacy}
                                    urlElectronicDisclosure={electronicDisclosure}
                                    urlCreditDisclosure={creditDisclosure}
                                    urlPartners={partners}
                                    checklistItem1={siteMeta.funnel.checklist.item1}
                                    checklistItem2={siteMeta.funnel.checklist.item2}
                                    checklistItem3={siteMeta.funnel.checklist.item3}
                                    depositInfoSubheading={siteMeta.funnel.depositInfo.subheading}
                                    depositInfoExpanderItem1Answer={siteMeta.funnel.depositInfo.expanderItem1.answer}
                                    depositInfoExpanderItem2Answer={siteMeta.funnel.depositInfo.expanderItem2.answer}
                                    depositInfoExpanderItem3Answer={siteMeta.funnel.depositInfo.expanderItem3.answer}
                                    depositInfoExpanderItem4Answer={siteMeta.funnel.depositInfo.expanderItem4.answer}
                                    initialLegalCertification={(
                                        <small>By submitting my information, I certify that I am a U.S. resident, at least 18 years of age, agree to the <Link to={privacy} tabIndex="-1" target="_blank">Privacy Policy</Link> and <Link to={terms} tabIndex="-1" target="blank">Terms of Web Site Use</Link>, and that I consent to receive offers from you and from third parties.</small>
                                    )}
                                    processingHeading="Lenders are reviewing your information."
                                    processingSubheading="If a lender is ready to work with you, you’ll be redirected to their&nbsp;website."
                                    secondChanceMessage1={siteMeta.secondChance.message.line1}
                                    secondChanceMessage2={siteMeta.secondChance.message.line2}
                                    resumeSubheading={(<span>When You Need It</span>)}
                                    disableCreditRatingStep
                                    enableProcessingWithOffer
                                    enableGoogleStreet
                                    showRLA={showRLA}
                                    requestedLoanAmountMin={finalLoanAmounts.min === 0 ? '' : finalLoanAmounts.min.toString()}
                                    requestedLoanAmountMax={finalLoanAmounts.max === 0 ? '' : finalLoanAmounts.max.toString()}
                                    enableTitleLoan
                                    enableDebtQuestions
                                    // callCenterPhoneNumber={callCenterPhoneNumber}
                                    // callCenterMessage={callCenterMessage}
                                />
                            </div>
                            <div className="form__footer">
                                <TrustIcons icons={['mcafee', 'secured']} siteMeta={siteMeta} height={26} />
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollMarker name="getStartedReveal"/>
                <div className="badges__as-seen">
                    <LogoAsSeenOnTV />
                    <LogoAsHeardOnRadio />
                </div>
                <div className="asSeenOn">
                    <LogoCnn />
                    <LogoFoxNews />
                    <LogoMsnbc />
                    <LogoVh1 />
                    <LogoHallmarkChannel />
                </div>
            </PageWrapper>
        );
    }
};

Form.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default Form;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;


